import './Produit.css'
function Extentions(){
    setTimeout(() => {
        
    



        var scrollTop = window.scrollY
        
        
        
        
        const menuBtn = document.querySelector('.menu-btn');
        let menuOpen = false;
        
        menuBtn.addEventListener('click', () => {
            if(!menuOpen) {
                menuBtn.classList.add('open');
                menuOpen = true;
            } else {
                menuBtn.classList.remove('open');
                menuOpen = false;
        }
        });
        
        const anchor = document.getElementById('anchor');
        const menu = document.getElementById('menu');
        console.log(menu.style.display == '')
        
        anchor.addEventListener('click', () => {
            console.log('clicked')
            if (menu.style.display == 'flex') {
                menu.style.display = '';
                console.log('removed')
            }
            else if(menu.style.display == '') {
                menu.style.display = 'flex';
                console.log('displayed')
            }
        
        })
        }, 100);
    return(
        <>
  <header>
        <nav>
            <input type="checkbox" id="check" />
            <label className="menu-btn" for="check">
                <div className="menu-btn__burger"></div>
            </label>
            <ul className="nav_links">
                <li className="li"><a className="hyper special" href="index.html"><img className="specimg" src="images/EBC_Logo.png" alt="" /></a></li>
                <li><a href="/">Accueil</a></li>
                <li className="dropli">
                    <div className="dropdown" id="anchor">
                        <a className="anchor">
                            Nos produits
                        </a>
                        <div className="menu" id="menu">
                            <div className="column">
                                <a href="verandas">Vérandas</a>
                                <a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a>
                                <a href="chassis">Châssis</a>
                                <a href="volets">Volets</a>
                            </div>
                            <div className="column">
                                <a href="pergolas">Pergolas</a>
                                <a href="extensions">Extensions d'habitat</a>
                                <a href="renovations">Rénovations & entretiens</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a href="About">À propos</a></li>
                <li><a href="realisations">Nos réalisations</a></li>
                <li><a href="Contact">Contact</a></li>
                <li className="img"></li>
            </ul>
            <div className="img1"></div>
        </nav>
        <div className="banderole">
            <div className="jvenm">devis & conseils gratuits</div>
            <div className="jvenm2">projets personalisés</div>
        </div>
    </header>
    
    <a className='form' href='contact'></a>

  <div className="backgroundprod renovations_bg">
        <h1>Rénovations & entretiens</h1>
    </div>

    <section className="grosse">
    
        <div className="expli-per renovations_expli gauchetext">
            <div className="filter">

                <h2>Lorem Ipsum</h2>
                <p>
                <span style={{fontWeight: 600}}>Rénovations & entretiens de maison : préservez et modernisez votre habitat</span> <br />
                Notre société vous propose des services complets pour la rénovation et l’entretien de votre maison. Que ce soit pour moderniser, améliorer l’isolation ou simplement entretenir, nous réalisons des travaux de qualité, respectant vos besoins et votre budget.                
                <br /><br />
                Avec notre savoir-faire, vous bénéficiez d'une maison rénovée, durable et en parfait état, prête à affronter le temps.                
                </p>
               
            </div>
        </div>
        <div className="expli-per-img renovations_img"></div>
    </section>

   
    <footer className='footerprod' >
    <section className="don">
            <a className="footerimage1" href="#"><img src="/logoNKI.webp" alt=""></img></a>
            <div className="col col1">
                <h1 style={{height: '50.5px'}}>NOS PRODUITS ET ACTIVITÉS</h1>
                <div className="prod">
                    <ul className="ul1">
                        <li><a href="verandas">Vérandas</a></li>
                        <li><a href="pergolas">Pergolas</a></li>
                        <li><a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a></li>
                        <li><a href="extensions">Extensions d'habitat</a></li>
                        <li><a href="chassis">Châssis</a></li>
                        <li><a href="renovations">Rénovations &<br />entretiens</a></li>
                        <li><a href="volets">Volets</a></li>
                    </ul>
                </div>
            </div>
            <div className="col col2">
                <h1 style={{height: '50.5px'}}>CONTACT</h1>
                <div className="grouping" style={{marginBottom:' 64px'}}>
                    <p style={{marginBottom: 10 + 'px'}}><b>No. de téléphone</b></p>
                    <p><u>Fixe</u>: 071/561010</p>
                    <p><u>Portable</u>: +32 498 56 01 70</p>
                    <p style={{marginBottom: 10 + 'px', marginTop: 20 + 'px'}}><b>E-mail:</b></p>
                    <p>info@n-k-i.com</p>
                </div>
            </div>
            <a className="footerimage2" href="#"><img src="/logoNKI.webp" alt=""></img></a>
        </section>
    </footer>
</>

    )
}
export default Extentions;