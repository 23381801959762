import './About.css'

function About(){
    setTimeout(() => {
        const menuBtn = document.querySelector('.menu-btn');
let menuOpen = false;

menuBtn.addEventListener('click', () => {
    if(!menuOpen) {
        menuBtn.classList.add('open');
        menuOpen = true;
    } else {
        menuBtn.classList.remove('open');
        menuOpen = false;
}
});

const anchor = document.getElementById('anchor');
const menu = document.getElementById('menu');
console.log(menu.style.display == '')

anchor.addEventListener('click', () => {
    console.log('clicked')
    if (menu.style.display == 'flex') {
        menu.style.display = '';
        console.log('removed')
    }
    else if(menu.style.display == '') {
        menu.style.display = 'flex';
        console.log('displayed')
    }

})
    }, 100);
    return(
        <div>
        <>
        <header>
        <nav>
            <input type="checkbox" id="check" />
            <label className="menu-btn" for="check">
                <div className="menu-btn__burger"></div>
            </label>
            <ul className="nav_links">
                <li className="li"><a className="hyper special" href="index.html"><img className="specimg" src="images/EBC_Logo.png" alt="" /></a></li>
                <li><a href="/">Accueil</a></li>
                <li className="dropli">
                    <div className="dropdown" id="anchor">
                        <a className="anchor">
                            Nos produits
                        </a>
                        <div className="menu" id="menu">
                            <div className="column">
                                <a href="verandas">Vérandas</a>
                                <a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a>
                                <a href="chassis">Châssis</a>
                                <a href="volets">Volets</a>
                            </div>
                            <div className="column">
                                <a href="pergolas">Pergolas</a>
                                <a href="extensions">Extensions d'habitat</a>
                                <a href="renovations">Rénovations & entretiens</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a href="About">À propos</a></li>
                <li><a href="realisations">Nos réalisations</a></li>
                <li><a href="Contact">Contact</a></li>
                <li className="img"></li>
            </ul>
            <div className="img1"></div>
        </nav>
        <div className="banderole">
            <div className="jvenm">devis & conseils gratuits</div>
            <div className="jvenm2">projets personalisés</div>
        </div>
    </header>
    
    <a className='form' href='contact'></a>

    <section className="sec sec1">
        <div className="about">
            <h1>À propos de NKI</h1>
            <p>Notre société belge de construction se distingue par une expertise solide et un savoir-faire acquis sur plus de 20 ans d'expérience. Dirigée par un patron ayant principalement travaillé sur des villas, des appartements et des projets résidentiels, notre entreprise se concentre avant tout sur ce type de réalisation. Bien que nous ayons eu l'occasion d'intervenir sur des grandes surfaces, ce sont les résidences privées qui constituent notre spécialité, où nous apportons un service sur mesure.</p>
        </div>
        <img src="/maison_pierre.jpg" alt="" />
    </section>


    <section className="sec sec3" id="sec3">
        <img id='img3' src="/pergola_travail.jpg" alt="" />
        <div id='txt3' className="trid">
            <p>Nous couvrons une large gamme de services allant du gros œuvre, garantissant la robustesse de vos bâtiments, au parachèvement, avec des finitions de haute qualité. De plus, nous sommes spécialisés dans la réalisation de vérandas et pergolas, parfaites pour l’aménagement de vos espaces extérieurs.</p>
        </div>
    </section>

    <section className="sec sec1 sec2">
        <div className="about">
            <p>
            Notre expertise, alliant construction et aménagement extérieur, vous assure des solutions complètes, durables et adaptées à vos besoins. Qu'il s'agisse de construire ou de rénover, nous accompagnons nos clients à chaque étape, en veillant à respecter les délais et les normes les plus strictes.
            <br /><br />
            Faites confiance à notre équipe pour réaliser vos projets avec professionnalisme et précision.
            </p>
        </div>
        <img src="/ptite_maison.jpg" alt="" />
    </section>


    <section className="sec contact">
        <h1>Vous souhaitez des conseils et un devis gratuit?</h1>
        <div className="btn"><a href="contact">Contactez-nous</a></div>
    </section>


    <footer style={{ position:'initial'}} >
    <section className="don">
            <a className="footerimage1" href="#"><img src="/logoNKI.webp" alt=""></img></a>
            <div className="col col1">
                <h1 style={{height: '50.5px'}}>NOS PRODUITS ET ACTIVITÉS</h1>
                <div className="prod">
                    <ul className="ul1">
                        <li><a href="verandas">Vérandas</a></li>
                        <li><a href="pergolas">Pergolas</a></li>
                        <li><a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a></li>
                        <li><a href="extensions">Extensions d'habitat</a></li>
                        <li><a href="chassis">Châssis</a></li>
                        <li><a href="renovations">Rénovations &<br />entretiens</a></li>
                        <li><a href="volets">Volets</a></li>
                    </ul>
                </div>
            </div>
            <div className="col col2">
                <h1 style={{height: '50.5px'}}>CONTACT</h1>
                <div className="grouping" style={{marginBottom:' 64px'}}>
                    <p style={{marginBottom: 10 + 'px'}}><b>No. de téléphone</b></p>
                    <p><u>Fixe</u>: 071/561010</p>
                    <p><u>Portable</u>: +32 498 56 01 70</p>
                    <p style={{marginBottom: 10 + 'px', marginTop: 20 + 'px'}}><b>E-mail:</b></p>
                    <p>info@n-k-i.com</p>
                </div>
            </div>
            <a className="footerimage2" href="#"><img src="/logoNKI.webp" alt=""></img></a>
        </section>
    </footer>
</>

    </div>
    )
   
}
export default About;